// JS
import './src/styles/global.css'

// Future - If you need to change to ES6
// EG to allow exports.* usage, replace the import line with:
//
//     const React = require("react")
//     require('./src/styles/global.css')


// Workaround for issue where Gatsby sometimes scrolls middle or bottom of page when clicking <Link>
// https://github.com/gatsbyjs/gatsby/issues/38201
// https://github.com/gatsbyjs/gatsby/issues/37719
export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const currentPosition = getSavedScrollPosition(location);

	setTimeout(() => {
		window.scrollTo(...(currentPosition || [0, 0]));
	}, 0);

	return false;
};
